<template>
	<div>
		<b-button v-if="isOngoing(row.item)" size="sm" v-b-tooltip.hover.top="'Re-check'" variant="dark"
			@click.stop="editRepairedBatch(row.item)" class="mr-1 mt-1">
			<em class="fa fa-pencil"></em>
		</b-button>
		<b-button v-if="isOngoing(row.item)" size="sm" v-b-tooltip.hover.top="'Next step'" variant="primary"
			@click.stop="updateRepairedBatch(row.item)" class="mr-1 mt-1">
			<em class="fa fa-forward"></em>
		</b-button>
		<div v-else>
			<i>No Further Action Needed</i>
		</div>
	</div>
</template>
  
<script>
import EventBus from '@/shared/event-bus';
import config from '@/config/env-constants';

export default {
	name: 'repaired-batch-row-actions',
	props: {
		row: {
			type: Object,
			required: true,
		},
		selAssetType: {
			type: Object,
			required: true
		},
		selRepairCondition: {
			type: Object,
			required: true
		},
		isSuperAdmin: {
			type: Boolean,
			required: true,
		},
		isViewer: {
			type: Boolean,
			required: true,
		},
	},
	computed: {
		status() {
			return this.row.item.status;
		},
	},
	methods: {
		updateRepairedBatch(item) {
			const { IN_PRODUCTION, IN_QC } = config.batchStatus;

			switch (item.status) {
				case IN_PRODUCTION.name:
					this.emitCheckRepairedBatchEvent(item);
					this.$bvModal.show('check-repaired-batch');
					break;
				case IN_QC.name:
					this.emitEndorseRepairedBatchEvent(item);
					this.$bvModal.show('endorse-repaired-batch');
					break;
				default:
					// Handle other cases if needed
					break;
			}
		},

		editRepairedBatch(item) {
			const { IN_PRODUCTION, IN_QC } = config.batchStatus;

			if (item.status === IN_PRODUCTION.name) {
				this.emitEditRepairedBatchEvent(item);
				this.$bvModal.show('create-repaired-batch');
			} else if (item.status === IN_QC.name) {
				this.emitCheckRepairedBatchEvent(item);
				this.$bvModal.show('check-repaired-batch');
			}
		},

		isOngoing(item) {
			return !item.completed && !this.isViewer;
		},

		emitCheckRepairedBatchEvent(item) {
			EventBus.$emit('onCheckRepairedBatch', {
				repairedBatch: item,
			});
		},

		emitEndorseRepairedBatchEvent(item) {
			EventBus.$emit('onEndorseRepairedBatch', {
				repairedBatch: item,
				assetType: this.selAssetType,
			});
		},

		emitEditRepairedBatchEvent(item) {
			EventBus.$emit('onEditRepairedBatch', {
				repairedBatch: item,
				repairConditions: this.selRepairCondition,
			});
		},
	},
};
</script>
  
<style scoped>
/* Add custom styles if needed */
</style>
  